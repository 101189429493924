@font-face {
  font-family: "Inter Regular";
  font-weight: 400;
  font-style: normal;
  font-display: block;
  src: url("https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2")
    format("woff2");
}

@font-face {
  font-family: "Inter Bold";
  font-weight: 700;
  font-style: normal;
  font-display: block;
  src: url("https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2")
    format("woff2");
}

@font-face {
  font-family: "Inter Extra Light";
  font-weight: 200;
  font-style: normal;
  font-display: block;
  src: url("https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2")
    format("woff2");
}

@font-face {
  font-family: "Inter Light";
  font-weight: 300;
  font-style: normal;
  font-display: block;
  src: url("https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2")
    format("woff2");
}

@font-face {
  font-family: "Inter Semi Bold";
  font-weight: 600;
  font-style: normal;
  font-display: block;
  src: url("https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2")
    format("woff2");
}

@font-face {
  font-family: "Inter Medium";
  font-weight: 500;
  font-style: normal;
  font-display: block;
  src: url("https://fonts.gstatic.com/s/inter/v12/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7.woff2")
    format("woff2");
}
