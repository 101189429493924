// This file is used to override the default styles of react-phone-number-input library

.phone-input {
  background: #fcfcfc;
  font-size: 1.2rem;
  font-family: $font-medium;
  border: 0;
  outline: none;
  height: 38px;
  width: 100%;
  padding-left: 10px;
}

.PhoneInputCountrySelect {
  padding: 6px 18px;
  height: 40px;
  width: 100%;
  background: #fcfcfc;
  border: 0.75px solid #eeeeee;
  border-radius: 6px;
  font-size: 1.2rem;
  font-family: $font-medium;
  box-shadow: none !important;
  &.error {
    border-color: red !important;
  }
  &:focus {
    border-color: #333333;
  }
  &:disabled {
    background-color: #f5f5f5;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
}

.dark-theme {
  .phone-input {
    background: $dark-mode-text-input-bg;
    color: $dark-mode-default-text-color;
  }

  .PhoneInputCountrySelect {
    background: $dark-mode-text-input-bg;
    border-color: $dark-mode-border-color;
    color: $dark-mode-default-text-color;
    &:focus-visible {
      border-color: $dark-mode-border-color;
    }
  }
}
