@use "/src/styles/variables.scss" as *;

.recovery_email_copy_wrapper {
  margin-top: 18px;
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 24px;
  font-family: $font-regular;
}

.recovery_email_cta {
  margin-bottom: 8px;
}

.auth_content {
  margin-top: 18px;
  .auth_copy {
    font-family: $font-regular;
    text-align: center;
    font-size: 1.8rem;
    line-height: 24px;
    color: #333333;
    max-width: 262px;
    text-overflow: ellipsis;
    overflow: hidden;
    &.is_mobile_app {
      margin: 0 auto;
      max-width: 300px;
    }
  }
  .auth_sub {
    font-family: $font-medium;
    font-size: 1.2rem;
    line-height: 16px;
    text-align: center;
    color: #71767a;
    margin-top: 8px;
  }
  &.is_mobile_app {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  &.auth_content_dark_mode {
    .auth_copy {
      color: $dark-mode-default-text-color;
    }
    .recovery_sub_text {
      color: $dark-mode-secondary-color;
    }
  }
}

.recovery_text_skelly_wrapper {
  margin-bottom: 33px;
}

.recovery_sub_text {
  font-family: $font-medium;
  font-size: 1.2rem;
  line-height: 16px;
  text-align: center;
  color: #71767a;
  margin-top: 8px;
}

.skip_recovery {
  text-align: center;
  font-family: $font-medium;
  font-size: 1.2rem;
  color: rgba(113, 118, 122, 0.5);
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: rgba(113, 118, 122, 0.85);
  }
  margin-bottom: 8px;
}

.recovery_input_err_wrapper {
  margin-bottom: 45px;
  span {
    bottom: unset;
  }
}
