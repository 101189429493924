@use "/src/styles/variables.scss" as *;

.nft_item_container {
  padding: 10px;
  width: 262px;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  margin-top: 18px;
  .nft_item_img {
    overflow: hidden;
    width: 242px;
    height: 222px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 242px;
      height: auto;
      object-fit: contain;
    }
    &.in_mobile_img {
      width: 100%;
      height: 264px;
      img {
        max-width: 288px;
      }
    }
  }
  &.in_mobile_cont {
    width: 100%;
  }
  &.dark_mode_nft_item_container {
    border-color: $dark-mode-border-color;
    .nft_details {
      .nft_token_id {
        color: $dark-mode-default-text-color;
      }
      .details_toggle {
        color: #3294ff;
      }
    }
    hr {
      border-top: 0.75px solid $dark-mode-border-color;
    }
  }
}

.nft_details {
  width: 100%;
  margin-top: 10px;
  display: flex;
  line-height: 16px;
  .nft_token_id {
    width: 135px;
    overflow: hidden;
    font-family: $font-medium;
    text-overflow: ellipsis;
    font-size: 1.2rem;
    color: #312b32;
  }
}

.details_toggle {
  font-family: $font-medium;
  font-size: 1.2rem;
  color: #026cdf;
  margin-left: auto;
  cursor: pointer;
  &:hover {
    color: #002fff;
  }
  &.open {
    color: #71767a;
  }
}

.nft_details_container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 130px;
  overflow-y: auto;
  hr {
    margin: 0;
    margin-top: 12px;
    border-top: 0.75px solid #eeeeee;
    opacity: 1;
  }
}

.nft_skelly {
  margin: 28px 0 10px;
}
