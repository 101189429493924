@use "../../styles/variables" as *;
.banner {
  background-color: red;
  text-align: center;
  padding: 4px;
  height: 30px;
  border-radius: 18px 18px 0 0;
  &.is_mobile {
    border-radius: 0;
  }
  .banner_text {
    color: #fcfcfc;
    font-family: $font-light;
    font-size: 1.4rem;
  }
}
