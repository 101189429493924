@import "./typography";
@import "./variables.scss";
@import "./forms.scss";
@import "./common";
@import "./animated.scss";
@import "./_react-phone-number-input-override";

:root {
  --toastify-color-progress-dark: #f9f9f9;
  --toastify-color-dark: #333333;
  --toastify-text-color-dark: #f9f9f9;
  --toastify-toast-width: 80%;
  --toastify-toast-min-height: 52px;
  --font-regular: "Inter Regular";
  --font-bold: "Inter Bold";
  --font-light: "Inter Light";
  --font-extra-light: "Inter Extra Light";
  --font-semi-bold: "Inter Semi Bold";
  --font-medium: "Inter Medium";
  --toastify-font-family: var(--font-medium);
  --root-font-size: 10px;
}

html {
  // For easy conversions from rem to px I have used 10px as the base font size
  // 1rem = 10px
  // 1.5rem = 15px
  height: 100%;
  background: transparent;
  font-size: var(--root-font-size);
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  background: transparent;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  background: transparent;
  align-items: center;
}

.not-found {
  font-family: $font-bold;
  text-align: center;
  b {
    font-size: 3rem;
    color: red;
  }
  a {
    float: left;
    padding-top: 10px;
    font-size: 0.7rem;
    padding-left: 10px;
  }
}

.frame-container {
  background-color: "#fff";
  border-radius: "18.5px";
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.frame-wrapper {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  max-height: 1000px;
  width: 314px;
  background: #fff;
  border-radius: 18.75px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  overflow: hidden;
  .close-frame {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    z-index: 999;
    cursor: pointer;
    img {
      width: 24px;
      height: 24px;
    }
    &.block-ui-open {
      display: none;
    }
    &.with-banner {
      top: 35px;
    }
  }
  &.short {
    max-height: 500px;
  }
}

.inner-wrapper {
  padding: 30px 26px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  &.inner-wrapper-mobile-screen {
    overflow-y: scroll;
    scrollbar-width: none;
    @media screen and (min-width: 500px) {
      padding: 36px 26px 10px;
    }
  }
}

.frame-cta {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: flex-start;
  margin: 24px 0 8px !important;
  .msg {
    position: absolute;
    width: 100%;
    top: -30px;
    font-family: $font-light;
    font-size: 0.75rem;
    &.err {
      color: red;
    }
    &.success {
      color: green;
    }
  }
}

.frame-copy {
  font-family: $font-regular;
  font-size: 1.8rem;
  line-height: 24px;
  text-align: center;
  margin: 18px 0;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  max-height: 150px;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.secured-by-footer {
  font-size: 1rem;
  text-align: center;
  font-family: $font-regular;
  line-height: 12px;
  color: #bababa;
  span {
    font-family: $font-medium;
  }
}

.value-tooltip {
  .tooltip-inner {
    font-size: 1.2rem;
  }
}

.toast-close-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
  cursor: pointer;
  color: #fff;

  span {
    font-size: 1.4rem;
    font-family: $font-semi-bold;
  }
  svg {
    width: 10px;
    height: 10px;
  }
}

.custom-overlay-layout {
  width: 100%;
  height: 100%;
  z-index: 999;
  border-radius: inherit;
  overflow: hidden;
  visibility: visible;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  &.transparent {
    background: transparent;
    visibility: hidden;
  }
}

.mobile-screen {
  width: 100%;
  height: 100%;
  max-width: 500px; // so on tablet it does not go full screen
  border-radius: 0px;
  position: relative;
  .frame-cta {
    margin-top: auto !important;
    padding-top: 28px;
  }
  .form-cta {
    margin-top: auto;
    padding-top: 28px;
  }
  .completed-state-cta {
    margin-top: auto;
  }
  .cross-svg-container {
    margin-top: auto;
  }
  .check-svg-container {
    margin-top: auto;
  }
  .Toastify__toast-container--bottom-center {
    bottom: 35px;
  }
  .otp-field-container {
    gap: 15px;
    .otp-field {
      width: 42px;
    }
  }
  form {
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .snackbar-wrapper {
    .wrapper-bg {
      border-radius: 0;
    }
  }
}

.dark-theme {
  background: $dark-mode-bg;
  .frame-copy {
    color: $dark-mode-default-text-color;
  }
  .text-input {
    background: $dark-mode-text-input-bg;
    border-color: $dark-mode-border-color;
    color: $dark-mode-default-text-color;
  }

  .otp-field {
    background: $dark-mode-text-input-bg;
    border-color: $dark-mode-border-color;
    color: $dark-mode-default-text-color;
    &:focus-visible {
      border-color: $dark-mode-border-color;
    }
  }
  .snackbar-wrapper {
    color: $dark-mode-default-text-color;
    .wrapper-bg {
      background: rgba(53, 53, 53, 0.75);
    }
  }
  .secured-by-footer {
    color: #5f5e70;
  }
  .custom-overlay {
    background: rgba(53, 53, 53, 0.75);
    .custom-modal {
      background: $dark-mode-bg;
      .custom-modal-copy {
        color: $dark-mode-default-text-color;
      }
    }
  }
  .completed-state {
    .completed-state-copy {
      color: $dark-mode-default-text-color;
    }
  }
  .close-frame {
    svg {
      fill: $dark-mode-svg-fill-color;
      color: $dark-mode-svg-fill-color;
      rect {
        fill: $dark-mode-svg-fill-color;
      }
      path {
        stroke: $dark-mode-secondary-color;
      }
    }
  }
}
