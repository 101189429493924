.form-cta {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: flex-start;
  margin-top: 24px;
  margin-bottom: 8px;
  .msg {
    position: absolute;
    width: 100%;
    top: -30px;
    font-family: $font-light;
    font-size: 0.75rem;
    &.err {
      color: red;
    }
    &.success {
      color: green;
    }
  }
}

.snackbar-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 18.75px;
  z-index: 999;
  .wrapper-bg {
    background: rgba(249, 249, 249, 0.75);
    backdrop-filter: blur(2px);
    width: 100%;
    height: 100%;
    border-radius: 18.75px;
    &.hide-bg {
      --toastify-toast-width: 92%;
      background-color: transparent;
      backdrop-filter: unset;
    }
    &.no-progress {
      .Toastify__toast-theme--dark {
        &::before {
          content: none;
        }
      }
    }
  }
}

.Toastify__toast {
  border-radius: 8px;
  padding: 14px 16px;
  align-items: center;
}

.Toastify__toast-container--bottom-center {
  bottom: -25px;
  font-size: 1.4rem;
  position: absolute;
  @media (max-width: 480px) {
    width: 85%;
    transform: translateX(9%);
  }
}

@keyframes custom__trackProgress {
  10%,
  0% {
    transform: scaleX(0);
  }
  30%,
  40% {
    transform: scaleX(0.4);
  }
  60%,
  70% {
    transform: scaleX(0.75);
  }
  80%,
  90% {
    transform: scaleX(0.9);
  }
  100%,
  100% {
    transform: scaleX(1);
  }
}

.Toastify__toast-theme--dark {
  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: scaleX(0);
    transform-origin: left;
    height: 100%;
    background: #484848;
    z-index: -1;
    animation-timing-function: cubic-bezier(0.25, 0.8, 0.8, 1);
    animation-duration: 4000ms;
    animation-name: custom__trackProgress;
    animation-fill-mode: forwards;
  }
}
