//colors
$color-white: #fff;
$color-light-gray: #eff2f5;
$color-mid-gray: #848484;
$color-dark-gray: #878787;
$color-gray: #aaaaaa;
$color-dark: #182550;
$color-error: #ff0000;
$color-black: #000;
$color-blue: #3559fc;

//dark mode colors
$dark-mode-bg: #272833;
$dark-mode-default-text-color: #f1f1f1;
$dark-mode-text-input-bg: #323140;
$dark-mode-border-color: #414052;
$dark-mode-svg-fill-color: #2c2d38;
$dark-mode-secondary-color: #9190a2;

//fonts
$font-regular: var(--font-regular);
$font-bold: var(--font-bold);
$font-light: var(--font-light);
$font-extra-light: var(--font-extra-light);
$font-semi-bold: var(--font-semi-bold);
$font-medium: var(--font-medium);
