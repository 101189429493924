.scale-in-center {
  animation: scale-in-center 1s ease-in;
}
@keyframes scale-in-center {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.delayed-fade-in {
  animation: delayed-fade-in 1s linear forwards;
  animation-delay: 1s;
  opacity: 0;
}
@keyframes delayed-fade-in {
  to {
    opacity: 1;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeInDown {
  animation-name: fadeInDown;
  animation-duration: 0.75s;
}
