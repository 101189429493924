@use "/src/styles/variables.scss" as *;

.auth_content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  margin-top: 18px;
  .auth_otp_title {
    display: none;
    font-size: 22px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
  }

  .auth_copy {
    font-family: $font-regular;
    font-size: 1.8rem;
    line-height: 24px;
    text-align: center;
    color: #333333;
    text-overflow: ellipsis;
    overflow: hidden;
    &.is_mobile_app {
      margin: 0 auto;
      max-width: 300px;
    }
  }
  .passkey {
    font-family: $font-regular;
    text-align: center;
    font-size: 1.8rem;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .passkey_svg_container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .auth_sub {
    font-family: $font-medium;
    font-size: 1.2rem;
    line-height: 16px;
    text-align: center;
    color: #71767a;
    margin-top: 8px;
  }
  &.is_mobile_app {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  &.dark_mode {
    .auth_otp_title {
      color: $dark-mode-default-text-color;
    }
    .auth_copy {
      color: $dark-mode-default-text-color;
    }
    .auth_sub {
      color: $dark-mode-secondary-color;
    }
    .passkey {
      color: $dark-mode-default-text-color;
    }
    .resend_sub {
      color: $dark-mode-secondary-color;
      .resend_text {
        color: $dark-mode-secondary-color;
      }
    }
  }
}

.single_text_skelly {
  margin: 10px 0 8px;
}

.resend_sub {
  margin-bottom: 15px;
  display: flex;
  font-size: 1.2rem;
  line-height: 18px;
  color: rgba(113, 118, 122, 0.75);
  justify-content: center;
  font-family: $font-medium;

  .resend_sub_copy {
    text-align: center;
  }

  .resend_text {
    border-bottom: 1px solid rgba(113, 118, 122, 0.35);
    color: rgba(113, 118, 122, 0.35);
    pointer-events: none;
    &.enabled {
      pointer-events: all;
      cursor: pointer;
      border-bottom: 1px solid rgba(113, 118, 122, 0.75);
      color: rgba(113, 118, 122, 0.75);
    }
    &.code_sent {
      pointer-events: none;
      color: #19c816;
      border-bottom: none;
    }
  }

  .timer_wrap {
    min-width: 28px;
  }
}

.retry_skelleton_wrapper {
  margin-top: 42px;
  &.single_mt {
    margin-top: 12px;
  }
}

.use_recovery_email_wrapper {
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  font-family: $font-medium;
  color: rgba(113, 118, 122, 0.75);
  margin-bottom: 15px;
  span {
    color: #026cdf;
    cursor: pointer;
    text-decoration: underline;
    &:hover {
      color: #1e41b3;
    }
  }
}

.id_section {
  font-family: $font-medium;
  font-size: 1.4rem;
  line-height: 16px;
  width: 100%;
  display: flex;
  justify-content: center;

  .id_copy {
    color: #000;
    max-width: 190px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 500;
  }

  span {
    color: #026cdf;
    cursor: pointer;
    margin-left: 8px;
  }

  &.dark_mode {
    .email_copy {
      color: $dark-mode-secondary-color;
    }
    span {
      color: #3294ff;
    }
  }
}
