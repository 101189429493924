.frame_logo {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 200px;
  height: 36px;
  margin: 0 auto;
  img {
    max-width: 200px;
    height: 36px;
    object-fit: contain;
  }
}
