@use "/src/styles/variables.scss" as *;

.usr_btn_skeleton {
  margin: 10px 0 8px;
}

.use_phone {
  margin-top: 15px;
  text-align: center;
  font-family: $font-medium;
  font-size: 1.2rem;
  color: rgba(113, 118, 122, 0.5);
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: rgba(113, 118, 122, 0.85);
  }
}
