.input-wrapper {
  position: relative;
  text-align: left;
  font-family: $font-regular;
  .eye-icon {
    position: absolute;
    top: 50%;
    right: 15px;
    cursor: pointer;
    color: #3559fc;
  }
  span {
    color: red;
    font-size: 1.2rem;
    position: absolute;
    font-family: $font-regular;
    bottom: -20px;
    width: 100%;
    text-align: center;
  }

  label {
    font-size: 0.75rem;
    font-family: $font-regular;
  }
}

.text-input {
  padding: 6px 18px;
  height: 40px;
  width: 100%;
  background: #fcfcfc;
  border: 0.75px solid #eeeeee;
  border-radius: 6px;
  font-size: 1.2rem;
  font-family: $font-medium;
  box-shadow: none !important;
  &.error {
    border-color: red !important;
  }
  &:focus {
    border-color: #333333;
  }
  &:disabled {
    background-color: #f5f5f5;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
}

.primary-btn {
  width: 100%;
  font-size: 1.4rem;
  font-family: $font-semi-bold;
  border-radius: 43.5px;
  color: $color-white;
  background: $color-black;
  height: 42px;
  line-height: 17px;
  padding: 6px 8.25px;
  border: none;
  transition: all 0.3s ease-in-out;
  box-shadow: none !important;
  transition: all 0.3s ease-in-out;
  &.lg {
    width: 100%;
  }
  &.round {
    border-radius: 43.5px;
  }
  &.clr-transp {
    background-color: transparent !important;
    color: $color-gray;
    border: 1px solid;
    border-color: $color-gray;
  }
  &:hover {
    opacity: 0.75;
  }
}

.otp-field-container {
  display: flex;
  margin: 18px 0 15px;
  justify-content: center;
  gap: 8px;
  align-items: center;
}

.otp-err {
  text-align: center;
  margin-top: 8px;
  color: red;
  font-size: 1.2rem;
  font-family: $font-regular;
}

.otp-field {
  width: 37px;
  height: 42px;
  border-radius: 5px;
  background: #fcfcfc;
  border: 1px solid #e6e6e6;
  box-shadow: none !important;
  text-align: center;
  font-family: $font-regular;
  font-size: 1.6rem;
  line-height: 19px;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
  &.error {
    border-color: $color-error;
  }
  &:focus-visible {
    border: 1px solid #e6e6e6;
    box-shadow: none !important;
  }
  &:disabled {
    background: #e9ecef;
    border-color: grey;
  }
}

.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  border-radius: inherit;
  overflow: hidden;
}
.loader-backdrop {
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  border-radius: inherit;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
}

.custom-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  border-radius: inherit;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(1.5px);
  display: flex;
  justify-content: center;
  align-items: center;
  &.mobile-overlay {
    border-radius: 0;
  }
}

.custom-modal {
  padding: 30px 26px;
  background: #ffffff;
  box-shadow: 0px 0px 31.5px rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  .custom-modal-copy {
    color: #4a4a4a;
    font-family: $font-regular;
    font-size: 1.8rem;
    text-align: center;
    line-height: 24px;
  }

  .custom-modal-cta {
    display: flex;
    gap: 18px;
    margin-top: 24px;
  }
}

* {
  padding: 0;
  margin: 0;
}

.completed-state {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: inherit;
  justify-content: center;
  align-items: center;

  .completed-state-copy {
    font-family: $font-regular;
    font-size: 1.8rem;
    text-align: center;
  }
  .completed-state-cta {
    width: 100%;
    margin: 24px 0 8px;
  }
}

.check-svg-container {
  margin: 11px 0;
  svg {
    width: 168px;
    height: 141px;
  }
}

.cross-svg-container {
  width: 100%;
  height: 110px;
  display: flex;
  justify-content: center;
  .wrapper {
    width: 85px;
    height: 85px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(230, 71, 66, 0.1);
    border-radius: 50%;
  }
}

.text-skeleton {
  text-align: center;
  line-height: 24px;
}

.single-text-skeleton {
  margin: 15px 0 20px;
}

.default-skeleton-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cta-skeleton {
  margin: 5px 0 9px;
}

.app-logo-skeleton {
  margin: 0px 0 20px;
}

.collapse {
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 4.5px;
    background: #bababa;
  }
}
