@use "/src/styles/variables.scss" as *;

.otp_skelly {
  margin-top: 18px;
}

.otp_wrapper {
  width: 100%;
  position: relative;
  // iOS specific fixes
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -webkit-overflow-scrolling: touch;

  .otp_err {
    color: $color-error;
    margin-bottom: 8px;
    text-align: center;
  }

  .otp_field_container {
    position: relative;
    width: 100%;
    margin: 18px 0 15px;

    // Hidden input styling
    .otp_hidden_input {
      position: absolute;
      opacity: 0;
      height: 42px;
      pointer-events: none;
      caret-color: transparent;
      // iOS specific fixes
      -webkit-user-select: text;
      user-select: text;
      -webkit-tap-highlight-color: transparent;

      /* Reset all styles */
      background: none;
      border: none;
      outline: none;
      padding: 0;
      margin: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      /* Remove text styling */
      color: transparent;
      text-shadow: none;
      -webkit-text-fill-color: transparent;

      /* Remove cursor */
      caret-color: transparent;

      /* Remove selection styling */
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;

      /* Remove iOS rounded corners */
      border-radius: 0;
    }

    // Visual boxes container
    .otp_boxes {
      display: flex;
      gap: 8px;
      justify-content: center;
      position: relative;
      z-index: 0;

      .otp_box {
        width: 42px;
        height: 42px;
        border: 1px solid #e6e6e6;
        border-radius: 5px;
        background: #fcfcfc;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $font-regular;
        font-size: 1.6rem;
        line-height: 19px;
        cursor: text;
        transition: all 0.2s ease;

        .otp_placeholder {
          color: #9ca3af; // Light gray color for otp_placeholder
          opacity: 0.5;
        }

        &.filled .otp_placeholder {
          display: none;
        }

        &.active .otp_placeholder {
          opacity: 0.7;
        }

        &.active {
          border-color: #3b82f6;
          border-width: 2px;
          background: #fff;
        }

        &.error {
          border-color: $color-error;
        }

        &.filled {
          background: #fff;
        }

        &:disabled {
          background: #e9ecef;
          cursor: not-allowed;
        }
      }
    }
  }
}

// Dark theme support
:global(.dark-theme) {
  .otp_box {
    background: #272833;
    border-color: #4b5563;
    color: #fff;

    .otp_placeholder {
      color: #6b7280; // Darker gray for dark theme
    }

    &.active {
      border-color: #60a5fa;
    }

    &.filled {
      background: #272833;
    }
  }
}
