@use "/src/styles/variables.scss" as *;

.details_row {
  display: flex;
  font-family: $font-medium;
  font-size: 1.05rem;
  justify-content: space-between;
  line-height: 15px;
  .details_label {
    color: #333333;
    font-family: $font-regular;
  }
  .value_item {
    color: #71767a;
    //enable after #675
    cursor: default;
    max-width: 115px;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      color: #026cdf;
      font-family: $font-semi-bold;
    }

    // If no label is provided, allow the value to expand to the full width
    &.no_label {
      max-width: 100%;
    }

    // If the value needs to truncated, do not allow it to move to the next line
    &.ellipse_text {
      white-space: nowrap;
    }
  }

  // Dark mode specific styles
  &.dark_mode_details_row {
    .details_label {
      color: $dark-mode-default-text-color;
    }
    .value_item {
      color: $dark-mode-secondary-color;
      &:hover {
        color: #3294ff;
      }
    }
  }
}

.toggler {
  padding: 12px 15px;
  text-align: left;
  font-family: $font-medium;
  font-size: 1.2rem;
  color: #71767a;
  line-height: 17px;
  cursor: pointer;
  position: relative;
  width: 100%;
  background: #f9f9f9;
  border: 1px solid #eeeeee;
  border-radius: 5.25px;
  border-bottom: 1px solid #eeeeee;
  margin-top: 12px;
  display: flex;
  &.is_open {
    border-radius: 5.25px 5.25px 0px 0px;
  }
  img {
    margin-left: auto;
    width: 8.5px;
    &.flip {
      transform: rotate(180deg);
    }
  }
  &.dark_mode_toggler {
    background: $dark-mode-bg;
    border-color: $dark-mode-border-color;
    border-bottom: 1px solid $dark-mode-border-color;
    color: $dark-mode-secondary-color;
  }
}
